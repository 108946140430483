<template>
  <dynamic-form
    :schema="formSchema"
    :button-data="submitButton"
    :is-disabled="isDisabled"
    :initial-field-value="initialFieldValue"
    form-class="justify-content-start"
    @submit="submit"
  >
    <template v-slot:error>
      <div class="server-error-message content mt-3" v-if="errorMessage.length">
        {{ errorMessage }}
        <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
      </div>
    </template>
  </dynamic-form>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useWebhookUpdating } from '@/application/composables/webhookUpdating.js'
export default {
  name: 'WebhookUpdatingForm',
  components: {
    'dynamic-form': defineAsyncComponent(() => import('@/application/components/forms/DynamicForm.vue')),
  },
  props: {
    urlFieldName: {
      type: String,
    },
    headerFieldName: {
      type: String,
    },
    license: {
      type: Object,
    },
    handler: {
      type: Function,
      required: false,
    },
    initial: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      formSchema,
      submitButton,
      isDisabled,
      submit,
      errorMessage,
      checkTouched,
      initialFieldValue,
    } = useWebhookUpdating(props.urlFieldName, props.headerFieldName, props.license)

    const handlerSubmit = props.handler ? props.handler : submit
    const initialData = props.initial ? props.initial : initialFieldValue

    return {
      formSchema,
      submitButton,
      isDisabled,
      submit: (data) => handlerSubmit(data),
      errorMessage,
      checkTouched,
      initialFieldValue: initialData,
    }
  },
}
</script>
