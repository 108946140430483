import { computed, ref, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { updateLicenseField } from '@/application/services/dashboard.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'
import { $vfm } from 'vue-final-modal'

export function useWebhookUpdating(url, header, license) {
  const store = useStore()
  const i18n = useI18n()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { responseErrorHandler } = useErrorHandler()
  const initialFieldValue = {
    [url]: license.webhook[url],
    [header]: license.webhook[header],
  }
  const formSchema = computed(() => [
    {
      label: i18n.t(url),
      name: url,
      as: 'input',
      type: 'text',
      rules: 'url',
      cols: 'col-12',
      inputClass: 'form-control',
      description: i18n.t('webhookURLDescription'),
      placeholder: 'e.g. https://api.yourdomain.com/endpoint',
    },
    {
      label: i18n.t(header),
      name: header,
      as: 'input',
      type: 'text',
      rules: 'max:256',
      cols: 'col-12',
      inputClass: 'form-control',
      isHTMLDescription: true,
      description: `<span>${i18n.t('webhookHeaderDescription_1')} <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization" rel="noopener noreferrer">HTTP Authorization ${i18n.t('header')}</a> ${i18n.t('webhookHeaderDescription_2')}.</span>`,
      placeholder: 'e.g. Bearer Rz3aZxYNQKcwgRva',
    },
  ])
  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t(`webhookSubmit.${url}`),
  }))
  const submit = (data) => {
    isDisabled.value = true
    updateLicenseField(data, license.license_key)
      .then(() => {
        isDisabled.value = false
        store.commit('changeWebhooksState', {
          license_key: license.license_key,
          data,
        })
        $vfm.show({
          component: defineAsyncComponent(() => import('@/application/components/modals/InfoModal.vue')),
          bind: {
            title: i18n.t('success'),
            content: i18n.t('successSaving'),
          },
          on: {
            close: () => { $vfm.hideAll() },
          },
        })
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
        $vfm.show({
          component: defineAsyncComponent(() => import('@/application/components/modals/InfoModal.vue')),
          bind: {
            title: i18n.t('error'),
            content: i18n.t('errorSaving'),
          },
          on: {
            close: () => { $vfm.hideAll() },
          },
        })
      })
  }
  return {
    formSchema,
    submitButton,
    submit: (data) => submit({ webhook: data }),
    isDisabled,
    errorMessage,
    initialFieldValue,
  }
}
